<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div class="row" style="text-align: left; background-color: #DFF6FF; padding: 15px; border-radius: 10px; margin: 2px; ">

            <div class="col-md-6 col-6">

        <div>
            <h6>Welcome</h6>
            <h6 style="color: #000;"> <i class="bi bi-person"></i> {{ username }} </h6> 
        </div>

            </div>

            <div class="col-md-6 col-6" style="text-align: right;">

                <button class="btn btn-danger" @click="logout()">Log Out</button>

            </div>

        </div>




        



    </div>
    </section>



<section style="padding-top: 20px;">

<div class="container">


    <div class="row">

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/categories" class="btn menu-btn"> <i class="bi bi-plus-lg text-info"></i> Book a lesson</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/student-lessons" class="btn menu-btn"> <i class="bi bi-grid text-info"></i> My lessons</router-link>
        </div>

        <div class="col-md-3" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/my-account" class="btn menu-btn"> <i class="bi bi-person-fill text-info"></i> My account</router-link>
        </div>

    </div>

</div>

</section>




<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            username : localStorage.getItem('first_name')
        }
    },

    methods : {
        logout(){
            localStorage.clear()
            this.$store.state.logged_in = false
            this.$router.push('/login')
        }
    },

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>