<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: left;">
            <router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>
            <h2>Shareholders</h2>
           

            <div class="shadow-lg rounded p-3">

                <p class="message-box" v-if="show_error">{{ message }}</p>

                <p style="color: #2175A8;">Add new</p>

                <form @submit.prevent="new_shareholder()" enctype="multipart/form-data">

                    <div class="row">
                        <div class="col-md-6">
                            <label>Name</label>
                            <input type="text" class="form-control input-design" placeholder="Enter name of shareholder" v-model="name">
                        </div>

                        <div class="col-md-6">
                            <label>Shares(%)</label>
                            <input type="text" class="form-control input-design" placeholder="Enter shares in %" v-model="shares">
                        </div>


                        <div class="col-md-6">
                            <label>Email</label>
                            <input type="email" class="form-control input-design" placeholder="Optional" v-model="email">
                        </div>



                        <div class="col-md-6">
                            <label>Phone</label>
                            <input type="text" class="form-control input-design" placeholder="Optional" v-model="phone">
                        </div>

                    

                        <div class="col-md-12" style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Submit</button>
                        </div>

                       

                    </div>

                </form>

            </div>

        </div>


        



    </div>
    </section>


    <section style="padding-top: 100px;">

        <div class="container shadow-lg rounded p-3">

            <div class="row">

                <div class="col-md-12">

                    <div>

                        <table class="table">

                            <thead>
                                <tr>

                                    <th>Name</th>
                                    <th>shares(%)</th>
                                    <th></th>
                                  
                                </tr>
                            </thead>

                            <tbody v-for="shareholder in shareholders">

                                <tr>
                                     <td> {{ shareholder.name }} </td>
                                     <td> {{ shareholder.shares }} </td>
                                     <td> <button class="btn btn-info" @click="get_share(shareholder.id)"> <i class="bi bi-pencil-square"></i> </button> <button class="btn btn-danger" @click="delete_share(shareholder.id)">x</button> </td>
                                 </tr>

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>

    </section>


    <div style="position: fixed; top: 0; right: 0; background-color: #fff;" class="shadow-lg rounded p-3 m-3" v-if="show_modal">

   <div class="row">

    <div class="col-md-8 col-8">
        <p style="color: #2175A8;">Edit shares</p>
    </div>

    <div class="col-md-4 col-4" style="text-align: right;">
        <button class="btn btn-danger btn-sm" @click="show_modal=false">x</button>
    </div>

   </div>

<form @submit.prevent="edit_shares()" enctype="multipart/form-data">

    <div class="row">
        <div class="col-md-6">
            <label>Name</label>
            <input type="text" class="form-control input-design" placeholder="Enter name of shareholder" v-model="name">
        </div>

        <div class="col-md-6">
            <label>Shares(%)</label>
            <input type="text" class="form-control input-design" placeholder="Enter shares in %" v-model="shares">
        </div>

        <div class="col-md-6">
            <label>Email</label>
            <input type="email" class="form-control input-design" placeholder="Optional" v-model="email">
        </div>

        <div class="col-md-6">
            <label>Phone</label>
            <input type="text" class="form-control input-design" placeholder="Optional" v-model="phone">
        </div>

        

    

        <div class="col-md-12" style="padding-top: 20px;">
            <button class="btn theme-btn-1">Save changes</button>
        </div>

       

    </div>

</form>  

    </div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    data (){
        return {
            name : '',
            shares : '',
            message : '',
            show_error : false,
            shareholders : [],
            show_modal : false,
            selected_share : '',
            email : '',
            phone : ''
        }
    },
    methods : {

        async delete_share(id){

const res  = await axios.post(this.$store.state.api_url+'api/delete-share',{
id : id
}).then(function(response){
return response.data
}).catch(function(error){
console.log(error)
})

this.manage_shares()

},

        async edit_shares(){

if(!this.name){
   this.message = 'Name is required'
   this.show_error = true
    return
}

if(!this.shares){
    this.message = 'Shares is required'
    this.show_error = true
    return
}

const res  = await axios.post(this.$store.state.api_url+'api/edit-shares',{
name : this.name,
shares : this.shares,
id : this.selected_share,
email : this.email,
phone : this.phone
}).then(function(response){
return response.data
}).catch(function(error){
console.log(error)
})

if(res=='success'){
alert('Changes saved')
this.manage_shares()
}else{
this.message = res
this.show_error = true
}

},

        async get_share(id){
            this.selected_share = id
            const res = await axios.get(this.$store.state.api_url+'api/get-share/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            if(res.length>0){
                res.forEach(data => {
                    this.name = data.name
                    this.shares = data.shares
                    this.show_modal = true
                    this.email = data.email
                    this.phone = data.phone
                });
            }
        },
       

        async manage_shares(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-shares').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.shareholders  = res
        },

                
        async new_shareholder(){

   if(!this.name){
      this.message = 'Name is required'
      this.show_error = true
       return
   }

   if(!this.shares){
       this.message = 'Shares is required'
       this.show_error = true
       return
   }

const res  = await axios.post(this.$store.state.api_url+'api/new-shareholder',{
   name : this.name,
   shares : this.shares,
   email : this.email,
   phone : this.phone
}).then(function(response){
   return response.data
}).catch(function(error){
   console.log(error)
})

if(res=='success'){
   this.message = 'Sharedholder added to list'
   this.show_error = true
   this.manage_shares()
}else{
   this.message = res
   this.show_error = true
}

},



    },

    created (){
        this.manage_shares()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>