<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <h3>Choose a course below</h3>

        <div class="row">
    
            <div class="col-md-4" v-for="course in courses">

                <div>
                    
                    <router-link class="btn shadow-lg category" :to="{ name : 'courses', params : { 'course_id' : course.id } }"> <i class="bi bi-grid-fill icon"></i> 
                    {{ course.name }} 
                    </router-link>

                </div>

            </div>

        </div>

        <p v-if="courses.length==0">No courses found</p>

    

    </div>
    </section>









<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
          courses : [],
          main_category : ''
        }
    },

    methods : {

        async fetch_courses(){
            const res = await axios.get(this.$store.state.api_url+'api/sub-categories/'+this.main_category).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.courses  = res
        },

        
    },
    created () {
        this.main_category = this.$route.params.category_name
        this.fetch_courses()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>

<style scoped>

.category{
    margin: 5px;
    width: 100%;
    text-align: left;
    padding: 30px;
    border-left: solid 2px #2175A8;
}

.category:hover{
    background-color: aliceblue;
    border-left: solid 2px #C28D00;
}

</style>