<template>

    <footer style="background-color: #171717; padding-top: 30px; margin-top: 100px;">

        <div class="container">

            <div class="row">


                <div class="col-md-12" style="text-align: center;">

                    <div>
                        <router-link to="/terms" class="btn course-icon">Terms & Conditions</router-link>
                        <router-link to="/refund-policy" class="btn course-icon">Refund Policy</router-link>
                        <router-link to="/privacy-policy" class="btn course-icon">Privacy Policy</router-link>
                        
                    </div>

                   <div style="padding-bottom: 10px;">
                    <a class="btn btn-lg course-icon"> <i class="bi bi-facebook"></i> </a>
                    <a class="btn btn-lg course-icon"> <i class="bi bi-instagram"></i> </a>
                    <a class="btn btn-lg course-icon"> <i class="bi bi-linkedin"></i> </a>
                   </div>

                    <h6 style="color: lightgrey;">Copyright © 2023 restonline.com</h6>

                </div>

            </div>

        </div>

    </footer>

</template>