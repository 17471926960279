<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <router-link to="/student-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

        <h2>My Account</h2>

        <form @submit.prevent="update_details()" class="shadow-lg p-3 rounded">
            

            <div class="row">

                <div class="col-md-6">
                    <label>First name</label>
                    <input type="text" class="form-control input-design" v-model="first_name">
                </div>

                <div class="col-md-6">
                    <label>Last name</label>
                    <input type="text" class="form-control input-design" v-model="last_name">
                </div>

                <div class="col-md-6">
                    <label>Email</label>
                    <input type="email" class="form-control input-design" v-model="email" disabled>
                </div>

                <div class="col-md-6">
                    <label>Phone</label>
                    <input type="text" class="form-control input-design" v-model="phone">
                </div>

                <div class="col-md-12" style="padding-top: 20px;">

                    <button class="btn theme-btn-1">Save changes</button>

                </div>

            </div>

        </form>


       <div style="padding-top: 30px;">
        <h2>Update Password</h2>
       </div>

        <form @submit.prevent="update_password()">

            <div class="row">

                <div class="col-md-6">
                    <input type="password" class="form-control input-design" v-model="password" placeholder="Enter new password">
                </div>

                <div class="col-md-12" style="padding-top: 20px;">

                    <button class="btn theme-btn-1">Save changes</button>

                </div>

            </div>

        </form>


    </div>
    </section>







<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            first_name : localStorage.getItem('first_name'),
            last_name : localStorage.getItem('last_name'),
            email : localStorage.getItem('email'),
            phone : localStorage.getItem('phone'),
            user_id : localStorage.getItem('user_id'),
            password : ''
        }
    },

    methods : {

        async update_details(){

            localStorage.setItem('first_name',this.first_name)
            localStorage.setItem('last_name',this.last_name)
            localStorage.setItem('email', this.email)
            localStorage.setItem('phone',this.phone)

            const res = await axios.post(this.$store.state.api_url+'api/update-account-details',{
                id : this.user_id,
                first_name : this.first_name,
                last_name : this.last_name,
                email : this.email,
                phone : this.phone
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Changes saved')
        },

        async update_password(){
    
            if(!this.password){
                alert('Please enter password')
                return
            }

const res = await axios.post(this.$store.state.api_url+'api/update-password',{
    id : this.user_id,
    password : this.password
}).then(function(response){
    return response.data
}).catch(function(error){
    console.log(error)
})
alert('Changes saved')
}
       
    },

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>