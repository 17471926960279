import { createStore } from 'vuex'

export default createStore({
  state: {
    api_url : 'https://server.retsonline.com/',
    logged_in : localStorage.getItem('logged_in'),
    first_name : localStorage.getItem('first_name'),
    last_name : localStorage.getItem('last_name'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone')
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
