<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

      <div class="row">

        <div class="col-md-4">

</div>

        <div class="col-md-4">

        

        <form @submit.prevent="get_email()">

        <div>
            <input type="email" class="form-control input-design" placeholder="Your email address" v-model="email">
        </div>

        <div style="padding-top: 20px;">
            <button :disabled="disabled" class="btn theme-btn-1" style="width: 100%;">{{ text }}</button>
        
        </div>

        </form>

       

        </div>

        <div class="col-md-4">

        </div>

      </div>

    </div>
    </section>





<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            email : '',
            disabled : false,
            text : 'Reset Password'
        }
    },

    methods : {
        async get_email(){

            if(!this.email){
                alert('Email is required')
                return 0
            }
            this.disabled = true
            this.text = 'Please Wait...'
            const res = await axios.post(this.$store.state.api_url+'api/reset-password',{
                email : this.email,
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert(res)

            this.disabled = false
            this.text = 'Reset Password'
        
        }
    },

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>