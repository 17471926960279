<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="background-color: #2175A8; padding: 5px; border-radius: 10px; margin-bottom: 10px;">
            <span style="color: #fff;">Do not have an account?</span><router-link class="btn" to="/register" style="color:#EBAB00;">Register here</router-link>
        </div>

      <div class="row">

        <div class="col-md-6 shadow-lg p-3">

            <div style="text-align: left; padding-bottom: 20px;">
            <p style="color: #2175A8;">Rets Online</p>
            <h2>Login</h2>
            <p>Sign in to access your account and book online courses.</p>
        </div>


        <form @submit.prevent="login()">

        <div>
            <input type="email" class="form-control input-design" placeholder="Email address" v-model="email">
        </div>

        <div style="padding-top: 15px;">
            <input type="password" class="form-control input-design" placeholder="Password" v-model="password">
        </div>

        <div style="padding-top: 20px;">
            <button class="btn theme-btn-1">Login</button>
            <router-link to="/forgot-password" class="btn text-danger">I have forgotten my password</router-link>
        </div>

        </form>

       

        </div>

        <div class="col-md-6" style="background-image: url('/assets/images/image-6.jpg'); background-size: cover;">

        </div>

      </div>

    </div>
    </section>





<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            email : '',
            password : '',
            details : []
        }
    },

    methods : {
        async login(){

            if(!this.email){
                alert('Email is required')
                return 0
            }

            if(!this.password){
                alert('Password is required')
                return 0
            }

            const res = await axios.post(this.$store.state.api_url+'api/login-now',{
                email : this.email,
                password : this.password
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            if(res.length==0){
                alert('Wrong email or password.Check your details and try again')
            }else{
                this.details = res
        res.forEach(data => {
                  localStorage.setItem('first_name',data.first_name)
                  localStorage.setItem('last_name',data.last_name)
                  localStorage.setItem('phone',data.phone)
                  localStorage.setItem('email',data.email)  
                  localStorage.setItem('logged_in',true)
                  localStorage.setItem('user_id',data.id)
                  localStorage.setItem('password',data.password)
                  this.$store.state.logged_in = true
                  this.$store.state.email = data.email
                  
                });
                
                this.$router.push('/student-dashboard')
            }
        }
    },

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>