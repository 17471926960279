<template>
    <Header />
    <section>

        <div class="container">

            <router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

        </div>

    <div class="container" style="padding-top: 10px;" v-if="!show_calendar">

        <div style="text-align: left;">
           
           
            <div class="row">

                <div class="col-md-6">
                    <h2>Manage Calendar</h2>
                </div>


                <div class="col-md-6" style="text-align: right ;">
                    <button class="btn btn-info" @click="show_calendar=true">Show calendar</button>

                </div>

            </div>
           

            <div class="shadow-lg rounded p-3">

                <p class="message-box" v-if="show_error">{{ message }}</p>

                <p style="color: #2175A8;">Add new</p>

              

                <form @submit.prevent="new_calendar()" enctype="multipart/form-data">

                    <div class="row">
                        <div class="col-md-6">
                            <label>Pick course</label>
                            <select class="form-control input-design" v-model="course">
                             <option v-for="course in courses" :value="course.id">{{ course.name }}</option>   
                            </select>
                        </div>

                        <div class="col-md-6">
                            <label>Pick teacher</label>
                            <select class="form-control input-design" v-model="teacher">
                             <option v-for="teacher in teachers" :value="teacher.id"> {{ teacher.title }}.  {{ teacher.first_name }} {{ teacher.last_name }}  </option>   
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label>Pick days</label>
                            <select class="form-select input-design" v-model="date">
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label>Start time</label>
                            <input type="time" class="form-control input-design"  v-model="start_time">
                        </div>

                        <div class="col-md-4">
                            <label>End time</label>
                            <input type="time" class="form-control input-design"  v-model="end_time">
                        </div>

                        <div class="col-md-4">
                            <label>Link</label>
                            <input type="link" class="form-control input-design"  v-model="link">
                        </div>

                            <div class="col-md-4">
                            <label>Video</label>
                            <input type="file" class="form-control input-design" @change="get_video">
                        </div>

                     

                        

                        <div class="col-md-12" style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Submit</button>
                        </div>

                       

                    </div>

                </form>

            </div>

        </div>


        



    </div>
    </section>


    <section style="padding-top: 10px;" v-if="show_calendar">

        <div class="container">

            <div class="row">

<div class="col-md-6">
    <h2>Calendar</h2>
</div>


<div class="col-md-6" style="text-align: right ;">
    <button class="btn btn-info" @click="show_calendar=false">Add new Calendar</button>

</div>

</div>

            <div class="row shadow-lg rounded p-3">

                <div class="col-md-12">

                

                    <div class="table-responsive">

                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Course</th>
                                    <th>Days</th>
                                    <th>Time</th>
                                    
                                    <th>Teacher</th>
                                    <th>Price</th>
                                    <th>Completed</th>
                                    <th> Action </th>
                                </tr>
                            </thead>

                            <tbody v-for="lesson in lessons">

                                <tr>
                                    <td> <button class="btn btn-link" @click="calendar_details(lesson.calendar_id)"> {{ lesson.course_name }} </button> </td>
                                    <td> {{ lesson.lesson_date }} </td>
                                    <td> {{ lesson.start_time}} - {{ lesson.end_time }} </td>
                                    <td> {{ lesson.title }}. {{ lesson.first_name }} {{ lesson.last_name }} </td>
                                    <td>Ksh. {{ lesson.price }} </td>
                                    <td>
            <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ lesson.status }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><button class="btn dropdown-item" @click="mark_lesson_completed(lesson.calendar_id)">yes</button></li>
                <li><button class="btn dropdown-item" @click="mark_lesson_not_completed(lesson.calendar_id)">no</button></li>
              
            </ul>
            </div>

            </td>

            <td> <button class="btn btn-info" @click="calendar_details(lesson.calendar_id)">Manage +</button> </td>
                                </tr>
                               

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>



        <div class="shadow-lg rounded p-3 m-3" style="position: absolute; top: 0; right: 0; bottom: 0; background-color: #fff; overflow: scroll;  " v-if="show_modal">

        <div class="row">

            <div class="col-md-6 col-8">
                <h4>Calendar Details</h4>
            </div>

            <div class="col-md-6 col-4" style="text-align: right;">
                <button class="btn btn-danger" @click="show_modal=false">x</button>
            </div>

        </div>


            <form @submit.prevent="edit_calendar()">

<div class="row">
    <div class="col-md-6">
        <label>Pick course</label>
        <select class="form-control input-design" v-model="course">
         <option v-for="course in courses" :value="course.id">{{ course.name }}</option>   
        </select>
    </div>

    <div class="col-md-6">
        <label>Pick teacher</label>
        <select class="form-control input-design" v-model="teacher">
         <option v-for="teacher in teachers" :value="teacher.id"> {{ teacher.title }}.  {{ teacher.first_name }} {{ teacher.last_name }}  </option>   
        </select>
    </div>

    <div class="col-md-4">
        <label>Pick days</label>
                            <select class="form-select input-design" v-model="date">
                            <option>Monday</option>
                            <option>Tuesday</option>
                            <option>Wednesday</option>
                            <option>Thursday</option>
                            <option>Friday</option>
                            <option>Saturday</option>
                            <option>Sunday</option>
                            </select>
    </div>

    <div class="col-md-4">
        <label>Start time</label>
        <input type="time" class="form-control input-design"  v-model="start_time">
    </div>

    <div class="col-md-4">
        <label>End time</label>
        <input type="time" class="form-control input-design"  v-model="end_time">
    </div>

    <div class="col-md-12">
        <label>Link</label>
        <input type="link" class="form-control input-design"  v-model="link">
    </div>

    

    <div class="col-md-12" style="padding-top: 20px;">
        <button class="btn theme-btn-1">Save changes</button>
    </div>

   

</div>

</form>

<div class="alert alert-info" style="margin-top: 20px;" v-if="disbursed">
<strong>Please note</strong> : Payment for this lesson has already been disbursed.This action cannot be done again
</div>


<div style="text-align: right;">

    <router-link :to="{name : 'calendar-bookings', params : {id : selected_course}  }" class="btn btn-info" style="margin-right: 10px;">View Bookings</router-link>

    <button class="btn btn-info" style="margin-right: 10px;" @click="disburse_funds(selected_course)" :disabled="disbursed" >Disburse funds</button>

    <button class="btn btn-danger" @click="delete_calendar(selected_course)">Delete</button>

</div>


<div style="text-align: left; margin-top: 30px; background-color: #DFF6FF; border-radius: 5px; padding: 20px;">
    <h6>Note:</h6>
    <p>You as the administrator can also attend this lesson online</p>
    <a :href="link" target="_blank" class="btn btn-danger"> <i class="bi bi-camera-reels"></i> Attend online</a>
</div>



<div style="margin-top: 20px;" v-if="disbursed">
    <!-- show lesson disbursements -->
    <h4>Disbursements</h4>
    <table class="table">

        <thead>
            <tr> <th>Teacher amount</th>  <th> Edel Amount </th> <th>Created</th> </tr>
        </thead>

        <tbody v-for=" d in disbursements ">
            <tr>
                <td>Ksh. {{ d.teacher_amount }} </td>
                <td>Ksh. {{ d.edel_amount }} </td>
                <td> {{ d.created }} </td>
            </tr>

        </tbody>

    </table>
</div>



        </div>




    </section>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    data (){
        return {
            message : '',
            show_error : false,
            lessons : [],
            teachers : [],
            course : '',
            teacher : '',
            date : '',
            start_time : '',
            end_time : '',
            details : [],
            selected_course : '',
            show_modal : false,
            show_calendar : false,
            disbursed : false,
            disbursements : [],
            link : '',
            video : ''
        }
    },
    methods : {

         get_video(e){
            this.video = e.target.files[0] 
        },

        async lesson_disbursements(){
            const res = await axios.get(this.$store.state.api_url+'api/lesson-disbursements/'+this.selected_course).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.disbursements = res
            
        },

        async disburse_funds(id){
            const res = await axios.post(this.$store.state.api_url+'api/disburse-funds',
            {
                calendar_id:id,
                course_id : this.course,
                teacher_id : this.teacher,
                lesson_date : this.date,
                start_time : this.start_time,
                end_time : this.end_time
                
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
          alert('Action successful.Payments disbursed to individual accounts')
          this.disbursed = true
          this.lesson_disbursements()
        },

        async delete_calendar(id){
            const res = await axios.post(this.$store.state.api_url+'api/delete-calendar',{id:id}).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Lesson removed from list')
            this.manage_lessons()
            this.show_modal = false
        },

        async calendar_details(id){
            this.selected_course = id
            const res = await axios.get(this.$store.state.api_url+'api/calendar-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.details = res

            res.forEach(data => {
                    this.date = data.lesson_date
                    this.start_time = data.start_time
                    this.end_time = data.end_time
                    this.course = data.course_id
                    this.teacher = data.teacher_id
                    this.disbursed = data.disbursed
                    this.link = data.link
            });
            this.lesson_disbursements()
            this.show_modal = true
            
        },

        async edit_calendar(){
            const res = await axios.post(this.$store.state.api_url+'api/edit-calendar',{
                id : this.selected_course,
                course : this.course,
                teacher : this.teacher,
                date : this.date,
                start_time : this.start_time,
                end_time : this.end_time,
                price : this.price,
                link : this.link
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Changes saved')
            this.manage_lessons()
            this.show_modal = false
        },

        async mark_lesson_completed(id){
            const res = await axios.post(this.$store.state.api_url+'api/mark-lesson-completed',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.manage_lessons()
            
            
        },


        async mark_lesson_not_completed(id){
            const res = await axios.post(this.$store.state.api_url+'api/mark-lesson-not-completed',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.manage_lessons()
            
        },

        async manage_lessons(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-lessons').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.lessons  = res
        },

        async course_to_pick(){
            const res = await axios.get(this.$store.state.api_url+'api/course-to-pick').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.courses  = res
        },
      

        async teacher_to_pick(){
            const res = await axios.get(this.$store.state.api_url+'api/teacher-to-pick').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.teachers  = res
        },

                
        async new_calendar(){


//uploa a file
const config = {
       headers: { 'content-type': 'multipart/form-data' }
   }
   //uploa a file


   if(!this.course){
      this.message = 'Course is required'
      this.show_error = true
       return
   }

   if(!this.teacher){
       this.message = 'Teacher is required'
       this.show_error = true
       return
   }

   if(!this.date){
      this.message = 'Date is required'
      this.show_error = true
       return
   }

   if(!this.start_time){
      this.message = 'Start time is required'
      this.show_error = true
       return
   }

   if(!this.end_time){
      this.message = 'End time is required'
      this.show_error = true
       return
   }

const res  = await axios.post(this.$store.state.api_url+'api/new-calendar',{
   course : this.course,
   teacher : this.teacher,
   date : this.date,
   start_time : this.start_time,
   end_time : this.end_time,
   price : this.price,
   link : this.link,
   video : this.video
},config).then(function(response){
   return response.data
}).catch(function(error){
   console.log(error)
})

if(res=='success'){
   this.message = 'Lesson added to list'
   this.show_error = true
   this.manage_lessons()
   
}else{
   this.message = res
   this.show_error = true
}

},



    },

    created (){
        this.course_to_pick()
        this.teacher_to_pick()
        this.manage_lessons()

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>