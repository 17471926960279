<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div class="row" style="text-align: left; background-color: #DFF6FF; padding: 15px; border-radius: 10px; margin: 2px; ">

            <div class="col-md-6 col-6">

        <div>
            <h6>Welcome</h6>
            <h6 style="color: #000;"> <i class="bi bi-person"></i> {{ first_name }} </h6> 
        </div>

            </div>

            <div class="col-md-6 col-6" style="text-align: right;">

                <button class="btn btn-danger" @click="logout()">Log Out</button>

            </div>

        </div>

    </div>
    </section>


    <section style="padding-top: 20px;">

        <div class="container">

            <div class="row">


                <div class="col-md-3 col-4" style="text-align: left;">

                    <router-link to="/my-disbursements" class="btn menu-btn">
                        <h5> <i class="bi bi-currency-dollar course-icon"></i> </h5>
                        <h6>My Disbursements</h6>
                    </router-link>

                </div>

            

            </div>

        </div>

    </section>



<section style="padding-top: 50px;">

<div class="container">

    <h3> <i class="bi bi-grid text-info"></i> Lessons on my calendar</h3>

    <div>

        <button  @click="calendar_details(lesson.calendar_id)" class="btn shadow-lg rounded p-3 m-3" style="width: 100%; text-align: left;" v-for="lesson in lessons" :class=" { 'lesson-border' : lesson.status=='yes', 'lesson-border-2' : lesson.status=='no' } " >
            <h6 style="color: #2175A8;">{{ lesson.course_name }}</h6>
            <p> <i class="bi bi-calendar course-icon"></i> {{ lesson.lesson_date }}, <i class="bi bi-clock course-icon"></i> {{ lesson.start_time }} - {{ lesson.end_time }} </p>
            <small> <i class="bi bi-person-fill"></i>{{ lesson.title }} {{ lesson.first_name }} {{ lesson.last_name }}  </small>

        </button>

    </div>


</div>

</section>


<div class="moda" v-if="show_modal">

<div class="moda-content" style="text-align: center;">
    <h1 class="text-danger"> <i class="bi bi-camera-reels-fill"></i> </h1>
    <h4 class="text-info">Attend lesson online</h4>
    <p>Note : Make sure your camera and microphone is connected and working properly</p>
    <h5>{{ date }}</h5>
    <h6> {{ time }} </h6>
    <a :href="link" target="_blank" class="btn btn-danger">Attend now</a>

    <div style="padding-top: 50px;">
        <button class="btn text-danger btn-lg" @click="show_modal=false"> <i class="bi bi-x-circle"></i> </button>
    </div>

</div>

</div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            first_name :  localStorage.getItem('first_name'),
            email : localStorage.getItem('email'),
            lessons : [],
            date : '',
            time : '',
            show_modal : false
        }
    },

    methods : {
        async calendar_details(id){
            const res = await axios.get(this.$store.state.api_url+'api/calendar-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.details = res

            this.details.forEach(data => {
                this.link = data.link 
                this.date = data.lesson_date,
                this.time = data.start_time + ' - '+ data.end_time  
            });

            this.show_modal = true
            
        },

        async teacher_lessons(){
            const res = await axios.get(this.$store.state.api_url+'api/teacher-lessons/'+this.email).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.lessons  = res
        },

        logout(){
            localStorage.clear()
            this.$router.push('/teacher-login')
        }
    },

created () {
    this.teacher_lessons()
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
}

</script>

<style scoped>
.lesson-border{
    border-left: solid 4px #2175A8;
}

.lesson-border-2{
    border-left: solid 4px #800;
}
</style>