<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: left;">
            <router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>
            <h2>Student Accounts</h2>
            <p style="color: #2175A8;">Manage student accounts from here</p>
        </div>
    </div>
    </section>



<section style="padding-top: 50px;">

<div class="container">

    <div class="shadow-lg rounded p-3">

        <div class="table-responsive">
           
            <table class="table">

                <thead>
                    <tr>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Phone </th>
                    <th>Created</th>
                    <th>Action...</th>
                    </tr>
                </thead>

                <tbody v-for=" account in accounts ">

                    <tr> 
                        <td> {{ account.first_name }} </td>
                        <td> {{ account.email }} </td>
                        <td> {{ account.phone }} </td>
                         <td> {{ account.created }} </td>

                         <td> <button class="btn btn-danger" @click="delete_student(account.email)">Delete</button> </td>
                     </tr>

                </tbody>

            </table>

        </div>


    </div>

</div>

</section>




<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            accounts : []
        }
    },

    methods : {

        async delete_student(email){
            const res = await axios.post(this.$store.state.api_url+'api/delete-student',{
                email : email
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.fetch_accounts()
        },
        
           async fetch_accounts(){
            const res = await axios.get(this.$store.state.api_url+'api/fetch-accounts').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.accounts = res
           } 
    },

    created () {
        this.fetch_accounts()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>