<template>
    <Header />

    <div class="container">

<router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

</div>


    <section v-if="!show_teachers">

   

    <div class="container" style="padding-top: 0px;">

        <div style="text-align: left;">
           
           

            <div class="row">

<div class="col-md-6 col-6">
    <h2>Manage Teachers</h2>
</div>


<div class="col-md-6 col-6" style="text-align: right ;">
    <button class="btn btn-info" @click="show_teachers=true">Show teachers</button>

</div>

</div>
           

            <div class="shadow-lg rounded p-3">

                <p class="message-box" v-if="show_error">{{ message }}</p>

                <p style="color: #2175A8;">Add new</p>

                <form @submit.prevent="new_teacher()" enctype="multipart/form-data">

                    <div class="row">

                        <div class="col-md-4">
                            <label>Choose title</label>
                           <select class="form-control input-design" v-model="title">
                            <option>Prof</option>
                            <option>Dr</option>
                            <option>Mr</option>
                            <option>Mrs</option>
                            <option>Miss</option>

                           </select>
                        </div>

                        <div class="col-md-4">
                            <label>First name</label>
                            <input type="text" class="form-control input-design" placeholder="Enter first name" v-model="first_name">
                        </div>

                        <div class="col-md-4">
                            <label>Last name</label>
                            <input type="text" class="form-control input-design" placeholder="Enter last name" v-model="last_name">
                        </div>

                        <div class="col-md-4">
                            <label>Email</label>
                            <input type="email" class="form-control input-design" placeholder="Enter email" v-model="email">
                        </div>

                        <div class="col-md-4">
                            <label>Phone(MPESA)</label>
                            <input type="text" class="form-control input-design" placeholder="Enter phone" v-model="phone">
                        </div>

                        <div class="col-md-4">
                            <label>Image</label>
                            <input type="file" class="form-control input-design" @change="get_image">
                        </div>

                        <div class="col-md-4">
                            <label>Speciality</label>
                            <input type="text" class="form-control input-design" v-model="speciality">
                        </div>

                        <div class="col-md-4">
                            <label>Password</label>
                            <input type="password" class="form-control input-design" v-model="password">
                        </div>

                        <div class="col-md-12" style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Submit</button>
                        </div>

                       

                    </div>

                </form>

            </div>

        </div>


        



    </div>
    </section>


    <section style="padding-top: 10px;" v-if="show_teachers">

        <div class="container">

            <div class="row">

<div class="col-md-6 col-6">
    <h2>Teachers</h2>
</div>


<div class="col-md-6 col-6" style="text-align: right ;">
    <button class="btn btn-info" @click="show_teachers=false">Add new teacher</button>

</div>

</div>

        </div>

        <div class="container shadow-lg rounded p-3">

            <div class="row">

                <div class="col-md-12">

                    <div class="table-responsive">

                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Speciality</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody v-for="teacher in teachers">

                                <tr>
                                    
                                     <td> <img :src="this.$store.state.api_url+'assets/teachers/'+teacher.image" style="height: 50px; width:50px; border-radius: 10px;" class="shadow-lg"> </td>
                                     <td> <button @click="teacher_details(teacher.id)" class="btn">{{ teacher.title }}.  {{ teacher.first_name }} {{ teacher.last_name }} </button>
                                    
                                     
                                    
                                    </td>
                                     <td> {{ teacher.email }} </td>
                                     <td> {{ teacher.phone }} </td>
                                     <td> {{ teacher.speciality }} </td>

                                     <td>  
                                        <button class="btn btn-danger " style="margin-right: 10px;" @click="delete_teacher(teacher.id)">Delete</button>
                                        <button class="btn btn-info" style="margin-right: 10px;" @click="teacher_information(teacher.id)">Edit</button>
                                       
                                        <button @click="teacher_details(teacher.id)" class="btn btn-info"> More + </button>  </td>
                                 </tr>

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>

    </section>


    <div style="position: fixed; right: 0; top:0; background-color: #fff; overflow:scroll; width: 80%; " class="shadow-lg rounded p-3 m-3" v-if="show_modal">

      <div class="row">

        <div class="col-md-8 col-10">
            <h5>Lessons assigned</h5>
        </div>

        <div class="col-md-4 col-2" style="text-align: right;">
            <button class="btn btn-danger" @click="show_modal=false">x</button>
        </div>

      </div>

        <div class="table-responsive">
        <table class="table">
            <thead>
                <tr> <th> Course </th> <th> Date </th> <th>Time</th>  <th>Price</th> <th>Completed</th> <th>Disbursed</th> </tr>
            </thead>

            <tbody v-for="detail in details">
                <tr>
                    <td> {{ detail.course_name }} </td>
                    <td> {{ detail.lesson_date }} </td>
                    <td> {{ detail.start_time }} -  {{ detail.end_time }} </td>
                    <td> Ksh.{{ detail.price }} </td>
                    <td> 
                        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ detail.status }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><button class="btn dropdown-item" @click="mark_lesson_completed(detail.calendar_id,detail.teacher_id)">yes</button></li>
                <li><button class="btn dropdown-item" @click="mark_lesson_not_completed(detail.calendar_id,detail.teacher_id)">no</button></li>
              
            </ul>
            </div>    
                    </td>

                    <td>
                        <p v-if="detail.disbursed" class="text-success">Yes</p>
                        <p v-if="!detail.disbursed" class="text-danger">No</p>
                    </td>

                </tr>
            </tbody>

        </table>
        </div>


        <div v-if="disbursements.length">
            <h4>Disbursements</h4>
    <table class="table">

        <thead>
            <tr> <th>Teacher amount</th>  <th> Edel Amount </th> <th>Created</th> </tr>
        </thead>

        <tbody v-for=" d in disbursements ">
            <tr>
                <td>Ksh. {{ d.teacher_amount }} </td>
                <td>Ksh. {{ d.edel_amount }} </td>
                <td> {{ d.created }} </td>
            </tr>

        </tbody>

    </table>
        </div>

    </div>




    <div v-if="show_form" style="position: fixed; top:0; right:0; background-color: #fff; " class="shadow-lg rounded p-3 m-3">

        <h2>Edit teacher information</h2>

        <form @submit.prevent="edit_teacher()" enctype="multipart/form-data">

<div class="row">

    <div class="col-md-4">
        <label>Choose title</label>
       <select class="form-control input-design" v-model="title">
        <option>Prof</option>
        <option>Dr</option>
        <option>Mr</option>
        <option>Mrs</option>
        <option>Miss</option>

       </select>
    </div>

    <div class="col-md-4">
        <label>First name</label>
        <input type="text" class="form-control input-design" placeholder="Enter first name" v-model="first_name">
    </div>

    <div class="col-md-4">
        <label>Last name</label>
        <input type="text" class="form-control input-design" placeholder="Enter last name" v-model="last_name">
    </div>

    <div class="col-md-4">
        <label>Email</label>
        <input type="email" class="form-control input-design" placeholder="Enter email" v-model="email">
    </div>

    <div class="col-md-4">
        <label>Phone(MPESA)</label>
        <input type="text" class="form-control input-design" placeholder="Enter phone" v-model="phone">
    </div>

   

    <div class="col-md-4">
        <label>Speciality</label>
        <input type="text" class="form-control input-design" v-model="speciality">
    </div>

    <div class="col-md-4">
        <label>Password</label>
        <input type="password" class="form-control input-design" v-model="password">
    </div>

    <div class="col-md-12" style="padding-top: 20px;">
        <button class="btn btn-danger" style="margin-right: 10px;" @click="show_form=false">Cancel</button>
        <button class="btn btn-info">Submit</button>
    </div>

   

</div>

</form>

    </div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    data (){
        return {
            first_name : '',
            last_name : '',
            image : '',
            email : '',
            phone : '',
            message : '',
            title : '',
            show_error : false,
            teachers : [],
            speciality : '',
            details : [],
            show_modal : false,
            password : '',
            show_teachers : false,
            selected_teacher : '',
            show_form : false,
            disbursements : []
            
        }
    },
    methods : {

        async teacher_disbursements(id){
            const res = await axios.get(this.$store.state.api_url+'api/teacher-disbursements/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.disbursements = res
            
        },

        async edit_teacher(){
            const res = await axios.post(this.$store.state.api_url+'api/edit-teacher',{
                id : this.selected_teacher,
                title : this.title,
                first_name : this.first_name,
                last_name : this.last_name,
                email : this.email,
                phone : this.phone,
                password : this.password,
                speciality : this.speciality


            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

           alert('Changes saved successfully')
           this.manage_teachers()
            
        },

        async teacher_information(id){
            this.selected_teacher = id
            const res = await axios.get(this.$store.state.api_url+'api/teacher-information/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            

            res.forEach(data => {
                this.title = data.title
                this.first_name = data.first_name
                this.last_name = data.last_name
                this.email = data.email
                this.phone = data.phone
                this.speciality = data.speciality
            });
            this.show_form = true
            
        },

        async delete_teacher(id){
            const res = await axios.post(this.$store.state.api_url+'api/delete-teacher',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.manage_teachers()
            
        },

        async mark_lesson_completed(id,teacher_id){
            const res = await axios.post(this.$store.state.api_url+'api/mark-lesson-completed',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.teacher_details(teacher_id)
            
        },


        async mark_lesson_not_completed(id,teacher_id){
            const res = await axios.post(this.$store.state.api_url+'api/mark-lesson-not-completed',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.teacher_details(teacher_id)
        },

        async teacher_details(id){
            
            const res = await axios.get(this.$store.state.api_url+'api/teacher-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.details  = res
            this.teacher_disbursements(id)
            this.show_modal = true
        },

        get_image(e){
            this.image = e.target.files[0] 
        },

        async manage_teachers(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-teachers').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.teachers  = res
        },

                
        async new_teacher(){

//uploa a file
const config = {
       headers: { 'content-type': 'multipart/form-data' }
   }
   //uploa a file

   if(!this.image){
      this.message = 'Image is required'
      this.show_error = true
       return
   }

   if(!this.first_name){
       this.message = 'First name is required'
       this.show_error = true
       return
   }

   if(!this.last_name){
      this.message = 'Last name is required'
      this.show_error = true
       return
   }

   if(!this.email){
      this.message = 'Email is required'
      this.show_error = true
       return
   }

   if(!this.phone){
      this.message = 'Phone is required'
      this.show_error = true
       return
   }

   if(!this.title){
      this.message = 'Title is required'
      this.show_error = true
       return
   }

   if(!this.speciality){
      this.message = 'Speciality is required'
      this.show_error = true
       return
   }


   if(!this.password){
      this.message = 'Password is required'
      this.show_error = true
       return
   }

const res  = await axios.post(this.$store.state.api_url+'api/new-teacher',{
   title : this.title,
   image : this.image,
   email : this.email,
   phone : this.phone,
   first_name : this.first_name,
   last_name : this.last_name,
   speciality : this.speciality,
   password : this.password

},config).then(function(response){
   return response.data
}).catch(function(error){
   console.log(error)
})

if(res=='success'){
   this.message = 'Teacher added to list'
   this.show_error = true
   this.manage_teachers()
}else{
   this.message = res
   this.show_error = true
}

},



    },

    created (){
        this.manage_teachers()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>