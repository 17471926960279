<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: left;">
            <h2>Terms & Conditions</h2>
          
        </div>

        <p>
            These terms and conditions ("Terms") govern your use of the eLearning website ("Website") operated by us. By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Website.


        </p>

        <h3>1. Acceptance of Terms:</h3>
        <p>
            By accessing the Website, you acknowledge that you have read, understood, and agree to abide by these Terms, as well as our Privacy Policy.
        </p>

        <h3>2. Use of the Website:</h3>

        <p>
The Website provides educational content, resources, courses, and materials ("Content"). You may use the Website for personal, non-commercial purposes only.
</p>
<p>
You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
</p>
<p>You agree not to use the Website for any unlawful, unauthorized, or prohibited purposes.
        </p>

    <h3>3. User Accounts:</h3>
    <p>
        You may be required to create an account to access certain features of the Website. You must provide accurate and complete information during the registration process.
    </p>

    <p>
    You are solely responsible for any activity that occurs under your account.
    </p>

    <h3>4. Content Ownership:</h3>

    <p>
    All Content on the Website is the property of the Company or its licensors and is protected by copyright and other intellectual property laws.
    </p>

    <p>
        You may not reproduce, distribute, modify, create derivative works, publicly display, or otherwise exploit any Content without the prior written consent of the Company.
    </p>

    <h3>5. Payments and Fees:</h3>

    <p>
    Some Content or services on the Website may be subject to payment of fees. You agree to pay all fees as indicated on the Website.
    </p>

    <p>
    Payments are non-refundable, unless otherwise stated in our refund policy.

    </p>

    <h3>
        6. User Conduct:
    </h3>

    <p>
        You agree not to engage in any conduct that could disrupt or interfere with the operation of the Website or the experience of other users.

    </p>

    <p>
    You must not upload, post, or transmit any content that is unlawful, offensive, defamatory, or infringes on the rights of others.
    </p>

    <h3>
        7. Termination:

    </h3>

    <p>
    We reserve the right to terminate or suspend your account and access to the Website at our discretion, without prior notice.
    </p>

    <p>
    Upon termination, you will no longer have access to your account or any Content associated with it.
    </p>

    <h3>
        8. Changes to Terms:

    </h3>



    <p>
    We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Website.

    </p>

    <p>
    Your continued use of the Website after the changes are posted constitutes your acceptance of the modified Terms.
    </p>


    <p>
        By using the Website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.

    </p>


    </div>
    </section>






<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    created () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>