<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <h3>Choose category below</h3>

        <div class="row">

            <div class="col-md-4" v-for="category in categories">

                <div>
                    
                    <router-link class="btn shadow-lg category" :to="{ name : 'sub-categories', params : { category_name : category.name } }"> <i class="bi bi-grid icon"></i> 
                    {{ category.name }} Courses
                    </router-link>

                </div>

            </div>

        </div>
        <p v-if="categories.length==0">No categories found</p>
    

    </div>
    </section>



    <section style="padding-top: 30px; margin: 10px;">

        <div class="container">

            <div class="row">

                <div class="col-md-6 col-2 shadow-lg" style="background-image: url('/assets/images/image-1.jpg'); background-size: cover; background-position: center; min-height: 200px; border-top-left-radius: 10px; border-bottom-left-radius: 10px; "></div>

                <div class="col-md-6 col-10 shadow-lg " style="background-color: aliceblue; padding-top: 30px; border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
                    <h1>Learn Online Easily</h1>

                    <p>Learning online gives you an opportunity to interact and network with a team of professionals from all over the globe</p>

                    <div style="padding-bottom: 20px;">
                        <router-link to="/register" class="btn theme-btn-2">Get started</router-link>
                    </div>

                </div>

            </div>

        </div>

    </section>





<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
          categories : [] 
        }
    },

    methods : {

        async manage_categories(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-categories').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.categories  = res
        },

        
    },
    created () {
        this.manage_categories()

        

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>

<style scoped>

.category{
    margin: 5px;
    width: 100%;
    text-align: left;
    padding: 30px;
    border-left: solid 2px #2175A8;
}

.category:hover{
    background-color: aliceblue;
    border-left: solid 2px #C28D00;
}

</style>