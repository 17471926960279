<template>
    <Header />

    <section style="padding-top: 0px;">

        <div class="container">

<router-link to="/manage-calendar" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

</div>

        <div class="container">

            <div class="row">
            
                <div class="col-md-6">
                    <h3>Calendar Bookings</h3>
                </div>

            </div>

        </div>

    </section>



    <section>

        <div class="container shadow-lg p-3 rounded">

            <div class="table-responsive">
              
                <table class="table">

                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Lesson</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Paid</th>
                            <th>Created</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody v-for="order in orders">
                        <tr>
                            <td class="text-success"> #{{ order.order_id }} </td>
                            <td> {{ order.course_name }} </td>
                            <td> {{ order.first_name }}  {{ order.last_name }} </td>
                            <td> {{ order.email }} </td>
                            <td> {{ order.phone }} </td>
                            <td class="text-info"> {{ order.paid }} </td>
                            <td> {{ order.created }} </td>
                            <td> <button class="btn btn-danger" @click="delete_order(order.id)">x</button> </td>
                        </tr>
                    </tbody>

                </table>
                
            </div>

        </div>

    </section>


    <Footer />

</template>


<script>
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import axios from 'axios'

export default {
    name : 'calendarbookings',
    components : {Header,Footer},
    data () {
        return {
            orders : [],
            id : ''
        }
    },

    methods : {
        async calendar_bookings(){
            const res  = await axios.get(this.$store.state.api_url+'api/calendar-bookings/'+this.id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.orders = res
        },

        async delete_order(id){
            const res  = await axios.post(this.$store.state.api_url+'api/delete-order',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Order removed from list')
            this.calendar_bookings()
        }
    },


    created () {
        this.id=this.$route.params.id;
        this.calendar_bookings()

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>