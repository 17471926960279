<template>

<section>

    <div class="container">

        <nav class="navbar header-design navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" style="color: #006186;"> <img src="/assets/images/logo.png" style="height: 40px;"> </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page" >Home</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/categories" class="nav-link active" aria-current="page" >Courses</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/how" class="nav-link active" aria-current="page" >How It Works</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/terms" class="nav-link active" aria-current="page" >Terms & Conditions</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/contact" class="nav-link active" aria-current="page" >Contact Us</router-link>
        </li>

       
    
      </ul>
      <div class="d-flex">
       
        <router-link to="/login" class="btn theme-btn-1">Login</router-link>
        <router-link to="/register" class="btn theme-btn-2">Register</router-link>
        
      </div>
    </div>
  </div>
</nav>


<div class="row">

  <div class="col-md-4">
    <div style="text-align: center; padding: 10px; margin-top: 20px; border-radius: 10px; color: grey; background-color: aliceblue; " v-if="this.$store.state.logged_in">
  We have new classes waiting for you <router-link to="/categories" class="btn btn-warning">Book Class</router-link>
</div>
  </div>
  
  <div class="col-md-4">
    <div style="text-align: center; padding: 10px; margin-top: 20px; border-radius: 10px; color: grey; background-color: aliceblue; " v-if="this.$store.state.logged_in">
  You might have some lessons  <router-link to="/student-lessons" class="btn btn-warning">View Calendar</router-link>
</div>
  </div>

  <div class="col-md-4">
    <div style="text-align: center; padding: 10px; margin-top: 20px; border-radius: 10px; color: grey; background-color: aliceblue; " v-if="this.$store.state.logged_in">
  You are logged as {{ this.$store.state.first_name }}  <router-link to="/student-dashboard" class="btn btn-info">Go to Dashboard</router-link>
</div>
  </div>
 

</div>




    </div>


</section>

</template>