<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

     

      <div class="row">

        <div class="col-md-6 shadow-lg p-3">

            <div style="text-align: left; padding-bottom: 20px;">
            <p style="color: #2175A8;">Rets Online</p>
            <h2>Login</h2>
            <p>This is a restricted page</p>
        </div>


        <form @submit.prevent="login()">

        <div>
            <input type="text" class="form-control input-design" placeholder="Username" v-model="username">
        </div>

        <div style="padding-top: 15px;">
            <input type="password" class="form-control input-design" placeholder="Password" v-model="password">
        </div>

        <div style="padding-top: 20px;">
            <button class="btn theme-btn-1">Login</button>
          
        </div>

        </form>

       

        </div>

        <div class="col-md-6" style="background-image: url('/assets/images/image-6.jpg'); background-size: cover;">

        </div>

      </div>

    </div>
    </section>





<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            username : '',
            password : '',
            details : []
        }
    },

    methods : {
        async login(){

            if(!this.username){
                alert('Username is required')
                return 0
            }

            if(!this.password){
                alert('Password is required')
                return 0
            }

            const res = await axios.post(this.$store.state.api_url+'api/admin-login',{
                username : this.username,
                password : this.password
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            if(res.length==0){
                alert('Wrong username or password.Check your details and try again')
            }else{
                this.$router.push('/admin-dashboard')
                localStorage.setItem('logged_in',true)
            }
        }
    },

    created(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>