import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Courses from '../components/Courses'
import How from '../components/How'
import Contact from '../components/Contact'
import ManageCourses from '../components/ManageCourses'
import ManageTeachers from '../components/ManageTeachers'
import ManageCalendar from '../components/ManageCalendar'
import Login from '../components/Login'
import Register from '../components/Register'
import StudentDashboard from '../components/StudentDashboard'
import AdminLogin from '../components/AdminLogin'
import AdminDashboard from '../components/AdminDashboard'
import StudentAccounts from '../components/StudentAccounts'
import Account from '../components/Account'
import Shareholders from '../components/Shareholders'
import TeacherLogin from '../components/TeacherLogin'
import TeacherDashboard from '../components/TeacherDashboard'

import MyDisbursements from '../components/MyDisbursements'
import CalendarBookings from '../components/CalendarBookings'
import StudentLessons from '../components/StudentLessons'

import Terms from '../components/Terms'
import PrivacyPolicy from '../components/PrivacyPolicy'
import RefundPolicy from '../components/RefundPolicy'

import CourseCategories from '../components/CourseCategories'

import Categories from '../components/Categories'

import SubCategories from  '../components/SubCategories'

import ForgotPassword from  '../components/ForgotPassword'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/courses/:course_id',
    name: 'courses',
    component: Courses
  },


  {
    path: '/how',
    name: 'how',
    component: How
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },

  {
    path: '/manage-courses',
    name: 'managecourses',
    component: ManageCourses
  },

  {
    path: '/manage-teachers',
    name: 'manageteachers',
    component: ManageTeachers
  },

  {
    path: '/manage-calendar',
    name: 'managecalendar',
    component: ManageCalendar
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/register',
    name: 'register',
    component: Register
  },

  {
    path: '/student-dashboard',
    name: 'studentdashboard',
    component: StudentDashboard
  },

  {
    path: '/admin-login',
    name: 'adminlogin',
    component: AdminLogin
  },
  {
  path: '/admin-dashboard',
  name: 'admindashboard',
  component: AdminDashboard
},

{
  path: '/student-accounts',
  name: 'studentaccounts',
  component: StudentAccounts
},

{
  path: '/my-account',
  name: 'myaccount',
  component: Account
},

{
  path: '/shareholders',
  name: 'shareholders',
  component: Shareholders
},

{
  path: '/teacher-login',
  name: 'teacherlogin',
  component: TeacherLogin
},

{
  path: '/teacher-dashboard',
  name: 'teacherdashboard',
  component: TeacherDashboard
},


{
  path: '/my-disbursements',
  name: 'mydisbursements',
  component: MyDisbursements
},

{
  path: '/calendar-bookings/:id',
  name: 'calendar-bookings',
  component: CalendarBookings
},

{
  path: '/student-lessons',
  name: 'student-lessons',
  component: StudentLessons
},

{
  path: '/terms',
  name: 'terms',
  component: Terms
},

{
  path: '/privacy-policy',
  name: 'privacy-policy',
  component: PrivacyPolicy
},

{
  path: '/refund-policy',
  name: 'refund-policy',
  component: RefundPolicy
},

{
  path: '/course-categories',
  name: 'course-categories',
  component: CourseCategories
},


{
  path: '/categories',
  name: 'categories',
  component: Categories
},

{
  path : '/sub-categories/:category_name',
  name : 'sub-categories',
  component : SubCategories
},

{
  path : '/forgot-password',
  name : 'forgot-password',
  component : ForgotPassword
}





]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
