<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

      

        <div>
            <h2>Online Courses Calendar</h2>
            <p style="color: #2175A8;">Pick a lesson or course from the calendar at meet the teacher on the day of the lesson</p>
        </div>

        <p v-if="courses.length==0">No classes found</p>


        <div class="row" style="padding-top: 30px;">

            <div class="col-md-4" v-for="course in courses">
               
<div to="" class="course-item shadow-lg">

    

    <div :style="{ backgroundImage : `url(${this.$store.state.api_url}assets/courses/${course.course_image})`  , backgroundPosition: 'center',  backgroundSize: 'cover', height: '200px' } "></div>
    <div style="padding: 10px;">

        <div class="row">
            <div class="col-md-8 col-8">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;"> {{ course.course_name }}</div>
               
            </div>
            <div class="col-md-4 col-4" style="text-align: right;">
                <h6 style="color: #2175A8;">Ksh.{{ course.price }}</h6>
            </div>
        </div>
        <p> <i class="bi bi-calendar course-icon"></i>   {{ course.lesson_date }} <i class="bi bi-clock course-icon"></i>  {{ course.start_time }} - {{ course.end_time }} </p>
        <button @click="select_lesson(course.course_name,course.price,course.lesson_date,course.start_time,course.end_time,course.course_id, course.calendar_id)" class="btn theme-btn-1">Book Course</button>
    </div>
</div>

</div>


        </div>



    </div>
    </section>


    <div class="moda" v-if="show_modal">
<div class="moda-content">

    <h6>You are about to book the following lesson</h6>
        <p style="color: #2175A8;">{{ name }}</p>
        <p> <i class="bi bi-calendar course-icon"></i> {{ date }}, <i class="bi bi-clock course-icon"></i> {{ start_time }} - {{ end_time }} </p>
        <p>Ksh. {{ price }} </p>

        <div>
          
            <button class="btn btn-danger" style="margin-right: 10px;" @click="show_modal=false">Cancel</button>
            <button :disabled="disabled" class="btn btn-info" @click="submit_order()">{{text}}</button>
        </div>
</div>

    </div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'

export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return  {
            courses : [],
            name : '',
            date : '',
            price : '',
            start_time : '',
            end_time : '',
            show_modal : false,
            order_id : '',
            calendar_id : '',
            course_id : '',
            main_course_id : '',
            disabled : false,
            text : 'Proceed'

        }
    },

    methods : {

        select_lesson(name,price,date,start_time,end_time,course_id,calendar_id){

            if(!localStorage.getItem('logged_in')){
            this.$router.push('/login')
        }else{
            this.name = name
            this.date = date
            this.start_time = start_time
            this.end_time = end_time
            this.price = price
            this.course_id = course_id
            this.calendar_id = calendar_id
            this.order_id = Math.floor((Math.random() * 100000) + 1)
            this.show_modal = true
        }

        },

        async fetch_courses(){
            const res = await axios.get(this.$store.state.api_url+'api/fetch-courses/'+this.main_course_id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.courses  = res
        },

        async submit_order(){
            this.disabled = true
            this.text = 'Please Wait...'
            const res = await axios.post(this.$store.state.api_url+'api/submit-order',{
                name : this.name,
                date : this.date,
                start_time : this.start_time,
                end_time : this.end_time,
                price : this.price,
                course_id : this.course_id,
                calendar_id : this.calendar_id,
                order_id : this.order_id,
                email : this.$store.state.email

            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.text = 'Proceed'
            this.disabled = false

            window.location.href = this.$store.state.api_url+'ipay/'+this.order_id+'/'+this.price+'/'+this.$store.state.email+'/'+this.$store.state.phone
        },
    },

    created () {
            this.main_course_id = this.$route.params.course_id
       
        this.fetch_courses()

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>