<template>
    <Header />
    <section>

        <div class="container" style="padding-top: 50px;">

            <div class="row shadow-lg rounded animate__animated animate__fadeIn">

                <div class="col-md-6" style="background-color: #F3F3F3; padding-top: 20px; border-right: #2175A8 solid 2px; padding-bottom: 30px; ">

                    <div class="alert alert-info">
                        Get 20% off discount when you book a lesson today
                    </div>

                    <h6 style="color: #800;">Welcome</h6>
                    <h1 style="font-size: 4vw; font-weight: bold;"><span :class=" {'animate__animated animate__fadeIn' : anim } ">{{text_1}}</span> <span style="color: #2175A8 ;" :class=" {'animate__animated animate__fadeIn' : anim2 } ">{{text_2}}</span></h1>

                    <div>
                        <p style="font-size: 20px;">Ignite Your Learning Journey with <span style="color: #2175A8 ;">Interactive Online Courses</span> </p>
                    </div>

                    <div>
                        <router-link to="/register" class="btn theme-btn-1">Get Started</router-link>

                        <router-link to="/how" class="btn theme-btn-2">Learn More</router-link>

                    </div>

                </div>


                <div class="col-md-6" style="padding: 0;">

                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
        <div style="background-image: url('/assets/images/image-1.jpg'); background-size: cover; background-position: center; min-height: 400px;"></div>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
        <div style="background-image: url('/assets/images/image-13.jpg'); background-size: cover; background-position: center; min-height: 400px;"></div>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
        <div style="background-image: url('/assets/images/image-10.jpg'); background-size: cover; background-position: center; min-height: 400px;"></div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

                </div>

            </div>

        </div>
      
    </section>



    





    <section style="padding-top: 100px;">

        <div class="container">

            <div class="row shadow-lg">


                <div class="col-md-6" style="padding-top: 100px; padding-bottom: 100px; background-color: #F3F3F3;">

                    <h1>Our <span style="color: #2175A8;">Live Learning</span> Sessions</h1>

                    <p>With our live learning sessions, you get a chance to meet with our teachers online and live!This imitates a classroom session where teachers and students can directly interact with each other.Students can ask questions and get realtime answers from our teachers.</p>

                    <div>
                        <router-link to="/register" class="btn theme-btn-2">Get Started</router-link>
                    </div>

                </div>


                <div class="col-md-6" style="background-image: url('/assets/images/image-3.jpg'); background-position: center; background-size: cover; min-height: 300px;">

                </div>

            </div>

        </div>


    </section>





    <section style="padding-top: 80px;">

        <div class="container">

            <div style="text-align: center;">

                <h1>How To Get Started?</h1>

            </div>


            <div class="row" style="padding-top: 30px;">

                <div class="col-md-3"></div>
                <div class="col-md-6">
                    
                    <div>
                        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Register for free & login</button>

                        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Pick a lesson or course from calendar</button>

                        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Pay & attend an onlne or pre-recorded session</button>

                    </div>

                </div>
                <div class="col-md-3"></div>

            </div>

        </div>

    </section>






    <section style="padding-top: 100px;">

<div class="container">

    <div class="row shadow-lg">


        <div class="col-md-6" style="background-image: url('/assets/images/image-2.jpg'); background-position: center; background-size: cover; min-height: 300px;">

</div>


        <div class="col-md-6" style="padding-top: 100px; padding-bottom: 100px; background-color: #F3F3F3;">

            <h1>Our <span style="color: #2175A8;">Pre-recorded</span> Sessions</h1>

            <p>With our pre-recorded sessions, you are able to learn from recorded sessions of lessons or courses from our teachers.This is suitable for students who want to jump into a quick lesson without the guidance of a teacher.It also gives you the flexibility as a student to easily access our online lessons and courses.</p>

            <div>
                <router-link to="/register" class="btn theme-btn-2">Get Started</router-link>
            </div>

        </div>



    </div>

</div>


</section>






<section style="padding-top: 100px;">

    <div class="container">

        <div>
            <video style="width: 100%;"  autoplay loop muted class="shadow-lg">
  <source src="/assets/videos/student.mp4" type="video/mp4">

Your browser does not support the video tag.
</video>
        </div>

    </div>

</section>






<section style="padding-top: 100px;">

    <div class="container">

        <div class="row shadow-lg" style="background-color: #DFF6FF;">

            <div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

                <h1 class="course-icon"> <i class="bi bi-telephone-plus-fill"></i> </h1>
                <h6>+254 780 722 722</h6>

            </div>



            <div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

<h1 class="course-icon"> <i class="bi bi-envelope"></i> </h1>
<h6>support@retsonline.com</h6>

</div>



<div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

<h1 class="course-icon"> <i class="bi bi-geo-alt"></i> </h1>
<h6>Karen Village, Room 9.</h6>
<p>P.O Box 26560-00100 Nairobi </p>

</div>

        </div>

    </div>

</section>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},


    data () {
        return  {
            courses : [],
            text_1 : 'Rets',
            text_2 : 'Online',
            anim : false,
            anim2 : false
        }
    },

    methods : {
        
    },

    created () {
        setInterval(() => {
        this.text_1 = 'Succeed' 
        this.text_2 = 'Today'     
        this.anim = true
        this.anim2 = false
        }, 3000);


        setInterval(() => {
        this.text_1 = 'Rets'    
        this.text_2 = 'Online'   
        this.anim2 = true
        this.anim = false
        }, 9000);

        setInterval(() => {
        this.text_1 = 'With Online'    
        this.text_2 = 'Courses'   
        this.anim2 = true
        this.anim = false
        }, 12000);

        window.scrollTo({ top: 0, behavior: 'smooth' });

        
       
    }
}

</script>