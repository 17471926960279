<template>
<Header />

<div class="container">
            <router-link to="/student-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

        </div>


<section style="padding-top: 30px;">

    <div class="container">

        <h3> <i class="bi bi-grid text-info"></i> Lessons on your calendar</h3>

    <div>
      
        <div class="btn shadow-lg rounded p-3 m-3 the-lesson" style="width: 100%; text-align: left;" v-for="lesson in orders" :class="{'lesson-design' : lesson.status=='yes' }">

           <div class="row">

            <div class="col-md-8">
                <h6 style="color: #2175A8;">{{ lesson.course_name }}</h6>
            </div>

            <div class="col-md-4" style="text-align: right;" v-if="lesson.status=='no'" >
                <button @click="calendar_details(lesson.calendar_id)" class="btn btn-info">Attend online</button>
            </div>

           </div>

            <p> <i class="bi bi-calendar course-icon"></i> {{ lesson.lesson_date }}, <i class="bi bi-clock course-icon"></i> {{ lesson.start_time }} - {{ lesson.end_time }} </p>

            <div class="row" v-if="lesson.status=='yes'">

                <div class="col-md-6">
                    <small class="text-success"> <i class="bi bi-bell icon"></i> This lesson was completed.Rate teacher</small>
                </div>

                <div class="col-md-6" style="text-align: right;">
                    <button class="btn stars-1" v-for="star in stars" :class="{'stars-2' : lesson.stars>=star }" @click="rate_lesson(star,lesson.teacher_id,lesson.calendar_id)"> <i class="bi bi-star-fill"></i> </button>
                </div>

            </div>
          

        </div>


     
    </div>

    </div>

</section>

<div class="moda" v-if="show_modal">

    <div class="moda-content" style="text-align: left;">
    
        <div v-if="link">
            <h4 class="text-info">Attend lesson online</h4>
        <p>Note : Make sure your camera and microphone is connected and working properly</p>
        <h5>{{ date }}</h5>
        <h6> {{ time }} </h6>
        <a :href="link" target="_blank" class="btn btn-danger"> <i class="bi bi-camera-reels"></i> Attend now</a>  
        </div>

        <div v-if="video_file">
            <h6>Course video</h6>
            <video style="width: 100%;" controls class="shadow-lg rounded">
  <source :src="this.$store.state.api_url+'assets/videos/'+video_file" type="video/mp4">
Your browser does not support the video tag.
</video>
        </div>

        <div style="padding-top: 50px;">
            <button class="btn theme-btn-1" @click="show_modal=false"> <i class="bi bi-x-circle"></i> Close window </button>
        </div>

    </div>

</div>


<Footer />
</template>


<script>

import Header from './layouts/Header'
import Footer from './layouts/Footer'
import axios from 'axios'

export default{
    name : 'student lessons',

    components : {Header,Footer},

    data () {
        return {
            email : '',
            orders : [],
            details : [],
            show_modal : false,
            link : '',
            date : '',
            time : '',
            stars : [1,2,3,4,5],
            video_file : ''
        }
    },

    methods : {
        
        async rate_lesson(rate,teacher_id,calendar_id){
            const res = await axios.post(this.$store.state.api_url+'api/rate-lesson',{
                email : this.email,
                rate : rate,
                teacher_id : teacher_id,
                calendar_id : calendar_id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.student_lessons()
            
        },

        async student_lessons(){
            const res = await axios.get(this.$store.state.api_url+'api/student-lessons/'+this.email).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.orders = res
        },

        async calendar_details(id){
            const res = await axios.get(this.$store.state.api_url+'api/calendar-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.details = res

            this.details.forEach(data => {
                this.link = data.link 
                this.date = data.lesson_date,
                this.time = data.start_time + ' - '+ data.end_time  
                this.video_file = data.video
            });

            this.show_modal = true
            
        },
    },

    created () {
        this.email = this.$store.state.email
        this.student_lessons()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

}

</script>


<style scoped>
.lesson-design{
    border-left: solid 2px #2175A8;
}

</style>


