<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: left;">
            <h2>Privacy Policy</h2>

            <p>
                Welcome to Rets Online. We are committed to protecting your privacy and providing a safe online experience for all of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services.
            </p>

            <h3>Information We Collect:</h3>
            <p>
                Personal Information: We may collect personal information that you provide to us, including but not limited to your name, email address, contact information, and payment details.
            </p>

            <p>
                User Content: Any content you upload, post, or submit on our platform, such as comments, discussions, assignments, and projects.

            </p>

            <p>
                Usage Data: We collect information about your interactions with our website, including your IP address, browser type, pages visited, time spent on pages, and other usage statistics.
            </p>

            <p>
                Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your experience, understand usage patterns, and improve our services.
            </p>

            <h3>
                How We Use Your Information:
            </h3>

            <p>
                Provide Services: We use your personal information to deliver the services you've requested, such as creating and managing your account, processing payments, and delivering course content.
            </p>


            <p>
                Communication: We may use your contact information to send you updates, notifications, promotional materials, and other relevant information. You can opt out of these communications at any time.

            </p>

            <p>
                Improve and Personalize: We analyze usage data to improve our services, customize your experience, and develop new features.

            </p>

            <p>
                Security: We use your information to maintain the security and integrity of our platform, including protecting against fraud, unauthorized access, and other misuse.

            </p>

           <h3>
            Sharing of Information:
           </h3>

           <p>
            We may share your personal information under the following circumstances:

           </p>

           <p>
            Service Providers: We may share your information with third-party service providers who assist us in delivering our services, such as payment processors and hosting services.

           </p>

           <p>
            Legal Requirements: We may disclose your information in response to legal requests, court orders, or to comply with applicable laws and regulations.


           </p>

           <p>
            Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.


           </p>

           <h3>
            Your Choices:
           </h3>

           <p>
            Account Information: You can update or delete your account information at any time by accessing your account settings.

           </p>

           <p>
            Cookies: You can manage cookies and tracking technologies through your browser settings.
           </p>

           <p>
            Marketing Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in the communication or by contacting us directly.

           </p>

           <h3>
            Data Security:
           </h3>

           <p>
            We take reasonable measures to protect your personal information from unauthorized access, loss, misuse, or alteration. However, no data transmission over the internet or electronic storage method is entirely secure.


           </p>



           <h3>
            Children's Privacy:


           </h3>


           <p>
            Our services are intended for users above a certain age (e.g., 13 or 16, depending on applicable laws). We do not knowingly collect personal information from children under the specified age. If you believe we have inadvertently collected such information, please contact us to request its deletion.


           </p>


           <h3>
            Changes to this Privacy Policy:


           </h3>

           <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any significant changes as required by law.


           </p>


          
        </div>


    </div>
    </section>






<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>