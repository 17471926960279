<template>
    <Header />


    <div class="container">

<router-link to="/teacher-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

</div>
    
    



<section style="padding-top: 20px;">

<div class="container shadow-lg p-3 rounded">

    <h3>My Disbursements</h3>

    <div>

        <table class="table">

            <thead>
                <tr> <th>Amount</th> <th>Created</th> </tr>
            </thead>

            <tbody v-for="d in disbursements">
                <tr> <td>Ksh. {{ d.teacher_amount }} </td> <td> {{ d.created }} </td> </tr>
            </tbody>

        </table>

    </div>


</div>

</section>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            first_name :  localStorage.getItem('first_name'),
            email : localStorage.getItem('email'),
            disbursements : [],
            id : localStorage.getItem('user_id')
        }
    },

    methods : {
        async my_disbursements(){
            const res = await axios.get(this.$store.state.api_url+'api/my-disbursements/'+this.id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.disbursements  = res
        },

       
    },

created () {
    this.my_disbursements()
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
}

</script>

<style scoped>
.lesson-border{
    border-left: solid 4px #2175A8;
}

.lesson-border-2{
    border-left: solid 4px #800;
}
</style>