<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: left;">
            <h2>Refund Policy</h2>
          
        </div>

        <p>
            Students who register for an online course occasionally change their mind for one reason or another. Regardless of the reason, we believe there should be a definite refund policy for students who decide not to take the course or postpone. Please note, changes in your circumstances are not the responsibility of RETS and neither can RETS be held liable for changes in your circumstances. In a situation where a student wishes to cancel or change their enrolment, and as a measure of goodwill, RETS will allow refunds for online courses under the following circumstances:
        </p>

        <ul>
            <li>The student/user did not access any portion of the online course AND the student/user requests a refund, in writing via email within seven business days from the date of the registration (email notification sent). </li>

            <li>
                There will be no refunds for any online courses once a course has been accessed in any manner or the student partly attends a live session. 
            </li>

            <li>
                Please understand that with the enrollment and accessing of your online course, you have read and agreed to the refund policy. There are no extensions granted for your course. If you do not complete your course by the end-date and time of commencement, there will be additional fees for extended access.
            </li>

            <li>
                If the student is unable to start class, a 100% program refund can be requested but no more than seven days from the program start date. If the student has not requested a refund prior to 7 days from the course start date, refunds are not applicable. The refund request must be in writing and sent to: support@retsonline.com.
            </li>

            <li>
                Once lessons have begun payment is non-refundable.  RETS does not, under any circumstance, give cash refunds to students who do not or cannot finish the course for which they have registered. It is the student's responsibility to comply with the established schedule.
            </li>

            <li>
                If you apply for a refund after 7 days our refund policy does not apply.  However, you will have the option to change your course to another without incurring additional charges. The onus is on the student to ensure and confirm that written notification of withdrawal is well communicated. 
            </li>

            <li>
                Exceptional Circumstances: Where a refund request is made outside of RETS refund period as described above, RETS might provide consideration for such requests.  Each request is different and the decision to grant a goodwill payment, is made by a committee of board members.
            </li>
        </ul>

        <p>
            Disclaimer: Every attempt is made to ensure all information from the online school is accurate and that the student has attained the competencies taught in a course. Beyond this point, the learner is responsible to maintain their acquired competencies, and apply acquired knowledge and skills in a way which is appropriate to the unique characteristics of each application. This will release RETS from any liability, action and claims of whatsoever nature in connection with, or arising from any such information, instruction, or advice, given by any student or ex-student, whether directions given during the course are followed or not.
        </p>


    </div>
    </section>






<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>