<template>
    <Header />
    

        <div class="container">
            <router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

        </div>
        <section v-if="!show_courses">

    <div class="container" style="padding-top: 0px;">

        <div style="text-align: left;">
          
           <div class="row">

            <div class="col-md-6">
                <h2>Course Categories</h2>
            </div>

            <div class="col-md-6" style="text-align: right;">
                <button class="btn btn-info" @click="show_courses=true">Show categories</button>
            </div>

           </div>
           

            <div class="shadow-lg rounded p-3">

                <p class="message-box" v-if="show_error">{{ message }}</p>

                <p style="color: #2175A8;">Add new</p>

                <form @submit.prevent="new_category()">

                    <div class="row">
                        <div class="col-md-6">
                            <label>Name of category</label>
                            <input type="text" class="form-control input-design" placeholder="Enter name of category" v-model="name">
                        </div>

                        <div class="col-md-6">
                            <label>Description</label>
                            <input type="text" class="form-control input-design" placeholder="Enter description" v-model="description">
                        </div>
                  

                        <div class="col-md-12" style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Submit</button>
                        </div>

                       

                    </div>

                </form>

            </div>

        </div>


        



    </div>
    </section>


    <section style="padding-top: 0px;"  v-if="show_courses">

        <div class="container">

                 
           <div class="row">

<div class="col-md-6">
    <h2>Courses</h2>
</div>

<div class="col-md-6" style="text-align: right;">
    <button class="btn btn-info" @click="show_courses=false">Add new category</button>
</div>

</div>

        </div>

        <div class="container shadow-lg rounded p-3">

            <div class="row">

                <div class="col-md-12">

                    <div>

                        <table class="table">

                            <thead>
                                <tr>
                                   
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody v-for="category in categories">

                                <tr>
                                   
                                     <td> {{ category.name }} </td>
                                     <td> {{ category.description }} </td>

                                     <td> <button class="btn btn-info" style="margin-right:10px;" 
                                        @click="category_details(category.id)">Edit</button>  <button class="btn btn-danger" @click="delete_category(category.id)">Delete</button> </td>
                                 </tr>

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>

    </section>


    <div class="shadow-lg rounded p-3 m-3" style="position: fixed; top: 0; right: 0; background-color:#fff ;" v-if="show_modal">

        <h4>Edit category</h4>

        <form @submit.prevent="edit_category()">

<div class="row">
    <div class="col-md-6">
        <label>Name of category</label>
        <input type="text" class="form-control input-design" placeholder="Enter name of course" v-model="name">
    </div>

    <div class="col-md-6">
        <label>Description</label>
        <input type="text" class="form-control input-design" placeholder="Enter description of course" v-model="description">
    </div>

   

    <div class="col-md-12" style="padding-top: 20px;">
        <button class="btn btn-danger" style="margin-right: 10px;" @click="show_modal=false">Cancel</button>
        <button class="btn btn-info">Save changes</button>
    </div>

   

</div>

</form>
    </div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    data (){
        return {
            name : '',
            description : '',
            message : '',
            show_error : false,
            categories : [],
            show_courses : false,
            details : [],
            selected_category : '',
            show_modal : false
        }
    },
    methods : {

        async edit_category(){
            const res = await axios.post(this.$store.state.api_url+'api/edit-category',{
                id : this.selected_category,
                name : this.name,
                description : this.description
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Changes saved successfully')
            this.manage_categories()
            this.show_modal = false
        },


        async category_details(id){
            this.selected_category = id
            const res = await axios.get(this.$store.state.api_url+'api/category-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.details = res

            res.forEach(data => {
               this.name = data.name
               this.description = data.description
            });

            this.show_modal = true
        },

        async delete_category(id){
            const res = await axios.post(this.$store.state.api_url+'api/delete-category',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            alert('Category removed from the list')
            this.manage_categories()
        },


      

        async manage_categories(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-categories').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.categories  = res
        },

                
        async new_category(){

            if(!this.name){
      this.message = 'Name is required'
      this.show_error = true
       return
   }

   if(!this.description){
       this.message = 'Description is required'
       this.show_error = true
       return
   }

const res  = await axios.post(this.$store.state.api_url+'api/new-category',{
   name : this.name,
   description : this.description,
}).then(function(response){
   return response.data
}).catch(function(error){
   console.log(error)
})

if(res=='success'){
   this.message = 'Category added to list'
   this.show_error = true
   this.manage_categories()
}else{
   this.message = res
   this.show_error = true
}

},



    },

    created (){
        this.manage_categories()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>