<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: center;">
            <h2>How It Works</h2>
        </div>

        <div style="text-align: center;">

<h3>How To Get Started?</h3>

</div>


<div class="row" style="padding-top: 30px;">

<div class="col-md-3"></div>
<div class="col-md-6">
    
    <div>
        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Register for free & login</button>

        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Pick a lesson or course from calendar</button>

        <button class="btn steps shadow-lg btn-lg"> <i class="bi bi-check-circle course-icon"></i> Pay & attend an onlne or pre-recorded session</button>

    </div>

</div>
<div class="col-md-3"></div>

</div>


    



    </div>
    </section>





    <section style="padding-top: 100px;">

<div class="container">

    <div class="row shadow-lg">


        <div class="col-md-6" style="padding-top: 100px; padding-bottom: 100px; background-color: #F3F3F3;">

            <h1>Our <span style="color: #2175A8;">Live Learning</span> Sessions</h1>

            <p>With our live learning sessions, you get a chance to meet with our teachers online and live!This imitates a classroom session where teachers and students can directly interact with each other.Students can ask questions and get realtime answers from our teachers.</p>

            <div>
                <router-link to="" class="btn theme-btn-2">Get Started</router-link>
            </div>

        </div>


        <div class="col-md-6" style="background-image: url('/assets/images/image-3.jpg'); background-position: center; background-size: cover; min-height: 300px;">

        </div>

    </div>

</div>


</section>




<section style="padding-top: 100px;">

<div class="container">

    <div class="row shadow-lg">


        <div class="col-md-6" style="background-image: url('/assets/images/image-2.jpg'); background-position: center; background-size: cover; min-height: 300px;">

</div>


        <div class="col-md-6" style="padding-top: 100px; padding-bottom: 100px; background-color: #F3F3F3;">

            <h1>Our <span style="color: #2175A8;">Pre-recorded</span> Sessions</h1>

            <p>With our pre-recorded sessions, you are able to learn from recorded sessions of lessons or courses from our teachers.This is suitable for students who want to jump into a quick lesson without the guidance of a teacher.It also gives you the flexibility as a student to easily access our online lessons and courses.</p>

            <div>
                <router-link to="" class="btn theme-btn-2">Get Started</router-link>
            </div>

        </div>



    </div>

</div>


</section>




<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>