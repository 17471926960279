<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="background-color: #2175A8; padding: 5px; border-radius: 10px; margin-bottom: 10px;">
            <span style="color: #fff;">Already have an account?</span><router-link class="btn" to="/login" style="color:#EBAB00;">Login here</router-link>
        </div>

      <div class="row">

        <div class="col-md-6 shadow-lg p-3">

            <div style="text-align: left; padding-bottom: 20px;">
            <p style="color: #2175A8;">Rets Online</p>
            <h2>Register</h2>
            <p>Register to access your account and book online courses.</p>
        </div>


        <form @submit.prevent="register()">

            <div class="row">

                <div class="col-md-6" style="padding-top: 15px;">
            <input class="form-control input-design" placeholder="First name" v-model="first_name">
        </div>

        <div class="col-md-6" style="padding-top: 15px;">
            <input class="form-control input-design" placeholder="Last name" v-model="last_name">
        </div>

        <div class="col-md-6" style="padding-top: 15px;">
            <input class="form-control input-design" placeholder="Email" v-model="email">
        </div>

        <div class="col-md-6" style="padding-top: 15px;">
            <input class="form-control input-design" placeholder="Phone  number" v-model="phone">
        </div>

        <div class="col-md-12" style="padding-top: 15px;">
            <input type="password" class="form-control input-design" placeholder="Password" v-model="password">
        </div>

        <div class="col-md-12" style="padding-top: 20px;">
            <button :disabled="disabled" class="btn theme-btn-1">{{text}}</button>
            
        </div>

            </div>

      

        </form>

       

        </div>

        <div class="col-md-6" style="background-image: url('/assets/images/image-6.jpg'); background-size: cover;">

        </div>

      </div>

    </div>
    </section>





<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
import axios from 'axios'
export default {
    name : 'home',
    components : {Header,Footer},

    data () {
        return {
            email : '',
            password : '',
            first_name : '',
            last_name : '',
            phone : '',
            disabled : false,
            text : 'Register'
        }
    },

    methods : {
        async register(){

            if(!this.first_name){
                alert('First name is required')
                return
            }

            if(!this.last_name){
                alert('Last name is required')
                return
            }

            if(!this.email){
                alert('Email  is required')
                return
            }

            if(!this.phone){
                alert('Phone  is required')
                return
            }

            if(!this.password){
                alert('Password is required')
                return
            }
            this.text = 'Please Wait...'
            this.disabled = true


            const res = await axios.post(this.$store.state.api_url+'api/register',{
                email : this.email,
                password : this.password,
                phone : this.phone,
                first_name : this.first_name,
                last_name : this.last_name
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            if(res=='success'){
                alert('Account created successfully.You can use your email and password to login and access your account.')
                this.text = 'Register'
                this.disabled = false
                this.$router.push('/login')
            }else{
                alert(res)
            }
        }
    },

    created () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>