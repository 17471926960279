<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div class="row" style="text-align: left; background-color: #DFF6FF; padding: 15px; border-radius: 10px; margin: 2px; ">

            <div class="col-md-6 col-6">

        <div>
            <h6>Welcome</h6>
            <h6 style="color: #000;"> <i class="bi bi-person"></i> Admin </h6> 
        </div>

            </div>

            <div class="col-md-6 col-6" style="text-align: right;">

                <button class="btn btn-danger" @click="logout()">Log Out</button>

            </div>

        </div>




        



    </div>
    </section>



<section style="padding-top: 50px;">

<div class="container">

    <p>Dashboard</p>

    <div class="row">

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/manage-teachers" class="btn menu-btn"> <i class="bi bi-people-fill course-icon"></i> <br/> Manage Teachers</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/course-categories" class="btn menu-btn"> <i class="bi bi-tags-fill course-icon"></i> <br/> Course Categories</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/manage-courses" class="btn menu-btn"> <i class="bi bi-grid-fill course-icon"></i> <br/> Manage Courses</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/manage-calendar" class="btn menu-btn"> <i class="bi bi-calendar course-icon"></i> <br/> Manage Calendar</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/student-accounts" class="btn menu-btn"> <i class="bi bi-people-fill course-icon"></i> <br/> Student Accounts</router-link>
        </div>

        <div class="col-md-3 col-6" style="text-align: left; padding-top: 10px; padding-bottom: 10px;">
            <router-link to="/shareholders" class="btn menu-btn"> <i class="bi bi-person-plus-fill course-icon"></i> <br/> Shareholders</router-link>
        </div>


    </div>

</div>

</section>




<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    methods : {
        logout(){
            localStorage.clear()
            this.$router.push('/admin-login')
        }
    },

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>