<template>
    <Header />
    <section>

    <div class="container" style="padding-top: 50px;">

        <div style="text-align: center;">
            <h2>Talk To Us</h2>
            <p style="color: #2175A8;">Call or email us any time.We respond within 24 hours</p>
        </div>


        



    </div>
    </section>



<section style="padding-top: 50px;">

<div class="container">

    <div class="row shadow-lg" style="background-color: #DFF6FF;">

<div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

    <h1 class="course-icon"> <i class="bi bi-telephone-plus-fill"></i> </h1>
    <h6>+254 780 722 722</h6>

</div>



<div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

<h1 class="course-icon"> <i class="bi bi-envelope"></i> </h1>
<h6>support@retsonline.com</h6>

</div>



<div class="col-md-4" style="text-align: center; padding-top: 30px; padding-bottom: 30px;">

<h1 class="course-icon"> <i class="bi bi-geo-alt"></i> </h1>
<h6>Karen Village, Room 9.</h6>
<p>P.O Box 26560-00100 Nairobi </p>

</div>

</div>

</div>

</section>




<Footer />
</template>


<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},

    created (){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>