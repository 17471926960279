<template>
    <Header />
    

        <div class="container">
            <router-link to="/admin-dashboard" class="btn"> <i class="bi bi-arrow-left"></i> Back </router-link>

        </div>
        <section v-if="!show_courses">

    <div class="container" style="padding-top: 0px;">

        <div style="text-align: left;">
          
           <div class="row">

            <div class="col-md-6">
                <h2>Manage Courses</h2>
            </div>

            <div class="col-md-6" style="text-align: right;">
                <button class="btn btn-info" @click="show_courses=true">Show courses</button>
            </div>

           </div>
           

            <div class="shadow-lg rounded p-3">

                <p class="message-box" v-if="show_error">{{ message }}</p>

                <p style="color: #2175A8;">Add new</p>

                <form @submit.prevent="new_course()" enctype="multipart/form-data">

                    <div class="row">
                        <div class="col-md-4">
                            <label>Name of course</label>
                            <input type="text" class="form-control input-design" placeholder="Enter name of course" v-model="name">
                        </div>

                        <div class="col-md-4">
                            <label>Price</label>
                            <input type="text" class="form-control input-design" placeholder="Enter price of course" v-model="price">
                        </div>

                        <div class="col-md-4">
                            <label>Description</label>
                            <input type="text" class="form-control input-design" placeholder="Enter description of course" v-model="description">
                        </div>

                        <div class="col-md-4">
                            <label>Image</label>
                            <input type="file" class="form-control input-design" @change="get_image">
                        </div>

                     


                        <div class="col-md-4">
                            <label>Choose category</label>
                            <select class="form-select input-design" v-model="category">
                            <option v-for="category in categories"> {{ category.name }} </option>
                            </select>
                        </div>


                      

                        <div class="col-md-12" style="padding-top: 20px;">
                            <button class="btn theme-btn-1">Submit</button>
                        </div>

                       

                    </div>

                </form>

            </div>

        </div>


        



    </div>
    </section>


    <section style="padding-top: 0px;"  v-if="show_courses">

        <div class="container">

                 
           <div class="row">

<div class="col-md-6">
    <h2>Courses</h2>
</div>

<div class="col-md-6" style="text-align: right;">
    <button class="btn btn-info" @click="show_courses=false">Add new course</button>
</div>

</div>

        </div>

        <div class="container shadow-lg rounded p-3">

            <div class="row">

                <div class="col-md-12">

                    <div>

                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody v-for="course in courses">

                                <tr>
                                     <td> <img :src="this.$store.state.api_url+'assets/courses/'+course.image" style="height: 50px; width:50px; border-radius: 10px;" class="shadow-lg"> </td>
                                     <td> {{ course.name }}
                                        <br />
                                        <small class="text-info">
                                        {{ course.category }}
                                     </small> </td>
                                     <td> Ksh.{{ course.price }} </td>
                                     <td> {{ course.description }} </td>

                                     <td> <button class="btn btn-info" style="margin-right:10px;" 
                                        @click="course_details(course.id)">Edit</button>  <button class="btn btn-danger" @click="delete_course(course.id)">Delete</button> </td>
                                 </tr>

                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>

    </section>


    <div class="shadow-lg rounded p-3 m-3" style="position: fixed; top: 0; right: 0; background-color:#fff ;" v-if="show_modal">

        <h4>Edit course</h4>

        <form @submit.prevent="edit_course()" enctype="multipart/form-data">

<div class="row">
    <div class="col-md-6">
        <label>Name of course</label>
        <input type="text" class="form-control input-design" placeholder="Enter name of course" v-model="name">
    </div>

    <div class="col-md-6">
        <label>Price</label>
        <input type="text" class="form-control input-design" placeholder="Enter price of course" v-model="price">
    </div>

    <div class="col-md-6">
        <label>Description</label>
        <input type="text" class="form-control input-design" placeholder="Enter description of course" v-model="description">
    </div>

   

    <div class="col-md-12" style="padding-top: 20px;">
        <button class="btn btn-danger" style="margin-right: 10px;" @click="show_modal=false">Cancel</button>
        <button class="btn btn-info">Save changes</button>
    </div>

   

</div>

</form>
    </div>




<Footer />
</template>


<script>
import axios from 'axios'
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
    name : 'home',
    components : {Header,Footer},
    data (){
        return {
            name : '',
            image : '',
            price : '',
            description : '',
            message : '',
            show_error : false,
            courses : [],
            show_courses : false,
            details : [],
            selected_course : '',
            show_modal : false,
            categories : [],
            category : ''
        }
    },
    methods : {

        async edit_course(){
            const res = await axios.post(this.$store.state.api_url+'api/edit-course',{
                id : this.selected_course,
                name : this.name,
                price : this.price,
                description : this.description
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            alert('Changes saved successfully')
            this.manage_courses()
            this.show_modal = false
        },


        async course_details(id){
            this.selected_course = id
            const res = await axios.get(this.$store.state.api_url+'api/course-details/'+id).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })
            this.details = res

            res.forEach(data => {
               this.name = data.name
               this.price = data.price
               this.description = data.description
            });

            this.show_modal = true
        },

        async delete_course(id){
            const res = await axios.post(this.$store.state.api_url+'api/delete-course',{
                id : id
            }).then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            alert('Course removed from the list')
            this.manage_courses()
        },


        get_image(e){
            this.image = e.target.files[0] 
        },

        

        async manage_courses(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-courses').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.courses  = res
        },


        async manage_categories(){
            const res = await axios.get(this.$store.state.api_url+'api/manage-categories').then(function(response){
                return response.data
            }).catch(function(error){
                console.log(error)
            })

            this.categories  = res
        },

                
        async new_course(){

//uploa a file
const config = {
       headers: { 'content-type': 'multipart/form-data' }
   }
   //uploa a file

   if(!this.image){
      this.message = 'Image is required'
      this.show_error = true
       return
   }

   if(!this.description){
       this.message = 'Description is required'
       this.show_error = true
       return
   }

   if(!this.name){
      this.message = 'Name is required'
      this.show_error = true
       return
   }

   if(!this.price){
      this.message = 'Price is required'
      this.show_error = true
       return
   }

   if(!this.category){
      this.message = 'Category is required'
      this.show_error = true
       return
   }

const res  = await axios.post(this.$store.state.api_url+'api/new-course',{
   name : this.name,
   image : this.image,
   description : this.description,
   price : this.price,
   category : this.category
},config).then(function(response){
   return response.data
}).catch(function(error){
   console.log(error)
})

if(res=='success'){
   this.message = 'Course added to list'
   this.show_error = true
   this.manage_courses()
}else{
   this.message = res
   this.show_error = true
}

},



    },

    created (){
        this.manage_courses()
        this.manage_categories()

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>